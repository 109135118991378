<template>
  <div
    id="multi-city"
    class="w-100"
  >
    <div
      v-for="(item, index) in searchData.flights"
      :key="index"
    >
      <b-badge
        variant="warning"
        class="font-medium-1"
      >
        #{{ index + 1 }}
      </b-badge>
      <b-row
        :class="isResultSearch ? 'mx-0' : 'mb-75'"
        no-gutters
      >
        <b-col
          cols="12"
          lg="9"
          class="section-airport d-flex flex-column flex-md-row justify-content-center align-items-center mb-50"
        >
          <!-- ANCHOR From -->
          <b-card
            no-body
            class="w-100 mb-50 p-25 mb-md-0"
            style="min-height: 67px"
          >
            <SearchAirportSelect
              :data-prop.sync="searchData.flights[index].startPoint"
              :type-prop="searchData.type"
              :is-search-class-booking.sync="searchData.isSearchClassBooking"
              :is-multi-city="true"
            />
          </b-card>

          <!-- ANCHOR switch -->
          <div :class="`switch_airport mx-auto mx-md-1 mx-lg-${isResultSearch ? '75' : '2'}`">
            <b-button
              variant="warning"
              :class="`btn-icon rounded-circle p-1 p-lg-${isResultSearch ? '75' : '1'} border`"
              :disabled="!(searchData.flights[index].startPoint && searchData.flights[index].endPoint)"
              @click="$emit('swap-from-to', index)"
            >
              <IAmIcon
                icon="swap"
                class="d-flex-center text-white"
                size="20px"
              />
            </b-button>
          </div>

          <!-- ANCHOR TO -->
          <b-card
            no-body
            class="w-100 mb-0 p-25"
            style="min-height: 67px"
          >
            <SearchAirportSelect
              :data-prop.sync="searchData.flights[index].endPoint"
              :type-prop="searchData.type"
              :is-search-class-booking.sync="searchData.isSearchClassBooking"
              :placeholder="'Chọn điểm đến'"
              :is-multi-city="true"
              right
            />
          </b-card>
        </b-col>

        <b-col
          cols="12"
          lg="3"
          class="d-flex"
        >
          <!-- ANCHOR Date -->
          <b-card
            no-body
            :class="`mb-0 w-50 p-50 mx-lg-${isResultSearch ? '75' : '1'} d-flex flex-nowrap align-items-center`"
            :style="`height: 67px;min-width: ${ isResultSearch ? '120px' : '150px'};max-width: 240px`"
          >
            <!-- ANCHOR Date -->
            <SearchDatePicker
              :date.sync="searchData.flights[index].departDate"
              class="flex-fill w-100"
              :config="{
                minDate: 'today',
              }"
              style="width: 160px"
              :flight="[searchData.flights[index].startPoint, searchData.flights[index].endPoint]"
            >
              <template #icon>
                <img
                  src="@icons/airplane-up.svg"
                  class="mr-50"
                  :class="searchData.type !== 'MC' ? '' : 'd-none'"
                >
              </template>
            </SearchDatePicker>
          </b-card>

          <!-- ANCHOR Button remove -->
          <div
            style="height: 67px"
            class="d-flex-center ml-50"
          >
            <b-button
              variant="white"
              class="p-50"
              :disabled="searchData.flights.length <= 2"
              style="border-radius: 8px"
              @click="handleRemoveFlight(index)"
            >
              <IconSvg
                :src="require('@icons/closeX.svg')"
                alt="remove"
                size="24px"
                :class="searchData.flights.length <= 2 ? 'text-text-secondary' : 'text-danger'"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-end mb-2">
      <b-button
        variant="white"
        :disabled="searchData.flights.length >= 5"
        class="d-flex-center"
        @click="handleAddFlight()"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25 text-warning fw-700"
          size="21"
        />
        <span class="font-medium-1 fw-600 text-body">{{ $t('flight.addAnotherFlight') }}</span>
      </b-button>
    </div>

    <b-card
      class="mb-1"
      no-body
    >
      <div :class="`d-flex flex-wrap flex-md-nowrap gap-1 py-50 d-flex-center mx-${ isResultSearch ? '0' : '2'} px-1`">
        <!-- ANCHOR Airlines -->
        <SearchSourceSelect
          style="min-width: 240px"
          :airlines.sync="searchData.airlines"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking="searchData.isSearchClassBooking"
          :permitted-carriers="searchData.permittedCarriers"
          class="divider-after flex-fill w-100"
        />

        <SearchCarriersSelect
          :carriers.sync="searchData.permittedCarriers"
          :is-disabled="isDisabledPermittedCariers"
          style="min-width: 160px"
          class="divider-after flex-fill w-100"
        />

        <!-- ANCHOR Search Combination Switch -->
        <SearchCombinationSwitch
          class="divider-after d-flex-center justify-content-between flex-fill"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking="searchData.isSearchClassBooking"
          :is-grouped-itinerary-response.sync="searchData.isGroupedItineraryResponse"
          :is-disabled-grouped-itinerary="isDisabledGroupedItinerary"
        />

        <!-- ANCHOR Passenger -->
        <SearchPassenger
          :adult.sync="searchData.adult"
          :child.sync="searchData.child"
          :infant.sync="searchData.infant"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking="searchData.isSearchClassBooking"
          class="flex-fill w-100"
        />

      </div>
    </b-card>

    <b-card
      class="mb-1"
      no-body
    >
      <div class="d-flex flex-wrap flex-md-nowrap gap-1 w-100 px-1 py-50 d-flex-center">
        <!-- ANCHOR Direct flight select -->
        <SearchDirectFlightSelect
          style="min-width: 240px"
          :number-of-stop.sync="searchData.numberOfStop"
          :is-search-class-booking="searchData.isSearchClassBooking"
          class="divider-after d-flex-center flex-fill"
        />

        <SearchClassFilter
          :cabin-preference.sync="searchData.cabinPreference"
          :is-disabled="searchData.isSearchClassBooking || searchData.isSearchMonthlyCheapestFare"
          class="divider-after w-100"
        />

        <!-- ANCHOR Promo Code -->
        <PromoCodeInput
          :promo-codes.sync="searchData.promoCodes"
          :airlines.sync="searchData.airlines"
          class="divider-after d-flex-center flex-fill"
        />

        <SearchMonthlyCheapestFare
          class="divider-after d-flex-center justify-content-between flex-fill"
          :is-search-monthly-cheapest-fare.sync="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking="searchData.isSearchClassBooking"
          :is-grouped-itinerary-response="searchData.isGroupedItineraryResponse"
        />

        <!-- ANCHOR Class Booking -->
        <SearchClassBookingSelect
          :is-search-class-booking.sync="searchData.isSearchClassBooking"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-grouped-itinerary-response="searchData.isGroupedItineraryResponse"
          class="flex-fill mr-md-5 ml-md-1"
        />

      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BButton, BCol, BRow, BBadge,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { useWindowSize } from '@vueuse/core'

import { convertISODateTime } from '@/@core/utils/filter'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCol,
    BRow,
    SearchAirportSelect: () => import('@flightv2/search/components/SearchAirportSelect.vue'),
    SearchSourceSelect: () => import('@flightv2/search/components/SearchSourceSelect.vue'),
    SearchPassenger: () => import('@flightv2/search/components/SearchPassenger.vue'),
    SearchDatePicker: () => import('@flightv2/search/components/SearchDatePicker.vue'),
    SearchDirectFlightSelect: () => import('@flightv2/search/components/SearchDirectFlightSelect.vue'),
    PromoCodeInput: () => import('@flightv2/search/components/PromoCodeInput.vue'),
    SearchClassBookingSelect: () => import('@flightv2/search/components/SearchClassBookingSelect.vue'),
    SearchClassFilter: () => import('@flightv2/search/components/SearchClassFilter.vue'),
    SearchMonthlyCheapestFare: () => import('@flightv2/search/components/SearchMonthlyCheapestFare.vue'),
    SearchCombinationSwitch: () => import('@flightv2/search/components/SearchCombinationSwitch.vue'),
    SearchCarriersSelect: () => import('@flightv2/search/components/SearchCarriersSelect.vue'),
  },
  props: {
    searchData: {
      type: Object,
      default: () => { },
    },
    isResultSearch: {
      type: Boolean,
      default: false,
    },
    isDisabledGroupedItinerary: {
      type: Boolean,
      default: false,
    },
    isDisabledPermittedCariers: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { width: windowWidth } = useWindowSize()
    const { toastError } = useToast()

    function addDays(date, days) {
      const result = new Date(date)
      result.setDate(result.getDate() + days)
      return convertISODateTime(result).dateFilter
    }

    const handleAddFlight = () => {
      const flightsLength = props.searchData.flights.length
      const previousFlight = cloneDeep(props.searchData.flights[flightsLength - 1])
      if (!previousFlight.endPoint) {
        toastError({
          title: 'Vui lòng chọn \'Điểm đến\' của hành trình trước.',
        })
        return
      }
      const newFlight = {
        startPoint: previousFlight.endPoint,
        endPoint: '',
        departDate: addDays(previousFlight.departDate, 3), // '2024-01-10',
        returnDate: previousFlight.returnDate, // '2023-12-30T00:00:00.000Z',
      }

      props.searchData.flights.push(newFlight)
    }

    if (props.searchData.flights.length < 2) {
      handleAddFlight()
    }
    const handleRemoveFlight = index => {
      props.searchData.flights.splice(index, 1)
    }
    return {
      handleAddFlight,
      handleRemoveFlight,
      windowWidth,
    }
  },
}
</script>

<style lang="scss" scoped>
#multi-city ::v-deep {
  .divider-after {
    &::after {
      content: "";
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #d3d3d3;
      height: 28px;
      margin: 0 1rem;

      @media (max-width: 768px) {
        content: none;
      }
    }
  }
}

.section-airport {
  position: relative;

  .switch_airport {
    @media (max-width: 767px) {
      position: absolute;
      z-index: 99;
      right: -45px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
