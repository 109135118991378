<template>
  <div
    id="round-trip"
    class="w-100"
  >
    <div class="section-airport w-100 d-flex flex-wrap flex-md-nowrap align-items-center mb-2">
      <!-- ANCHOR From -->
      <b-card
        class="flex-grow-1 mb-1 mb-md-0 w-100 p-75"
        no-body
      >
        <div class="ml-1 text-body">
          {{ $t('flight.startFrom') }}
        </div>
        <SearchAirportSelect
          :data-prop.sync="searchData.flights[0].startPoint"
          :is-search-class-booking.sync="searchData.isSearchClassBooking"
        />
      </b-card>

      <!-- ANCHOR switch -->
      <div class="switch_airport mx-auto mx-md-2 flex-shrink-1">
        <b-button
          variant="warning"
          class="btn-icon rounded-circle p-2 p-lg-1 border"
          :disabled="!(searchData.flights[0].startPoint && searchData.flights[0].endPoint)"
          @click="$emit('swap-from-to')"
        >
          <IAmIcon
            icon="swap"
            class="d-flex-center text-white"
            size="20px"
          />
        </b-button>
      </div>

      <!-- ANCHOR TO -->
      <b-card
        class="flex-grow-1 mb-0 w-100 p-75"
        no-body
      >
        <div class="ml-1 text-body">
          {{ $t('flight.endAt') }}
        </div>
        <SearchAirportSelect
          :data-prop.sync="searchData.flights[0].endPoint"
          :is-search-class-booking.sync="searchData.isSearchClassBooking"
          right
        />
      </b-card>
    </div>

    <b-card
      class="mb-2"
      no-body
    >
      <div class="d-flex flex-wrap flex-md-nowrap m-1">
        <!-- ANCHOR Depart Date -->
        <b-col
          cols
          class="d-flex align-items-center divider-after"
        >
          <SearchDatePicker
            :date.sync="searchData.flights[0].departDate"
            :config="{
              minDate: 'today',
            }"
            class="w-100"
            :icon="'@icons/airplane-up.svg'"
            :flight="[searchData.flights[0].startPoint, searchData.flights[0].endPoint]"
            @input="handleDepartDate"
          >
            <template #icon>
              <img
                src="@icons/airplane-up.svg"
                class="mr-50"
              >
            </template>
          </SearchDatePicker>
        </b-col>

        <!-- ANCHOR Return Date -->
        <b-col
          cols
          class="d-flex align-items-center"
        >
          <SearchDatePicker
            :date.sync="searchData.flights[0].returnDate"
            :config="{
              minDate: searchData.flights[0].departDate,
            }"
            class="w-100"
            :flight="[searchData.flights[0].endPoint, searchData.flights[0].startPoint]"
          >
            <template #icon>
              <img
                src="@icons/airplane-down.svg"
                class="mr-50"
              >
            </template>
          </SearchDatePicker>
        </b-col>
      </div>
    </b-card>

    <b-card
      class="mb-2"
      no-body
    >
      <div :class="`d-flex flex-wrap flex-md-nowrap gap-1 w-100 px-1 py-50 d-flex-center mx-${ isResultSearch ? '0' : '1'}`">
        <!-- ANCHOR Airlines -->
        <SearchSourceSelect
          style="min-width: 240px"
          :airlines.sync="searchData.airlines"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking="searchData.isSearchClassBooking"
          :permitted-carriers="searchData.permittedCarriers"
          class="divider-after flex-fill w-100"
        />

        <SearchCarriersSelect
          :carriers.sync="searchData.permittedCarriers"
          :is-disabled="isDisabledPermittedCariers"
          style="min-width: 160px"
          class="divider-after flex-fill w-100"
        />

        <!-- ANCHOR Search Combination Switch -->
        <SearchCombinationSwitch
          class="divider-after d-flex-center justify-content-between flex-fill"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking="searchData.isSearchClassBooking"
          :is-grouped-itinerary-response.sync="searchData.isGroupedItineraryResponse"
          :is-disabled-grouped-itinerary="isDisabledGroupedItinerary"
        />

        <!-- ANCHOR Passenger -->
        <SearchPassenger
          :adult.sync="searchData.adult"
          :child.sync="searchData.child"
          :infant.sync="searchData.infant"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking="searchData.isSearchClassBooking"
          class="flex-fill w-100"
        />
      </div>
    </b-card>

    <b-card
      class="mb-2"
      no-body
    >
      <div class="d-flex flex-wrap flex-md-nowrap gap-1 w-100 px-1 py-50 d-flex-center">
        <!-- ANCHOR Direct flight select -->
        <SearchDirectFlightSelect
          style="min-width: 240px"
          :number-of-stop.sync="searchData.numberOfStop"
          :is-search-class-booking="searchData.isSearchClassBooking"
          class="divider-after d-flex-center flex-fill"
        />

        <SearchClassFilter
          :cabin-preference.sync="searchData.cabinPreference"
          :is-disabled="searchData.isSearchClassBooking || searchData.isSearchMonthlyCheapestFare"
          class="divider-after w-100"
        />

        <PromoCodeInput
          :promo-codes.sync="searchData.promoCodes"
          :airlines.sync="searchData.airlines"
          class="divider-after d-flex-center flex-fill w-100"
        />

        <!-- ANCHOR Month Cheapest Fare -->
        <SearchMonthlyCheapestFare
          class="divider-after d-flex-center justify-content-between flex-fill"
          :is-search-monthly-cheapest-fare.sync="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking="searchData.isSearchClassBooking"
          :is-grouped-itinerary-response="searchData.isGroupedItineraryResponse"
        />

        <!-- ANCHOR Class Booking -->
        <SearchClassBookingSelect
          class="flex-fill mr-md-5 ml-md-1"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-search-class-booking.sync="searchData.isSearchClassBooking"
          :is-grouped-itinerary-response="searchData.isGroupedItineraryResponse"
        />
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BButton,
  BCol,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BCol,
    SearchAirportSelect: () => import('@flightv2/search/components/SearchAirportSelect.vue'),
    SearchSourceSelect: () => import('@flightv2/search/components/SearchSourceSelect.vue'),
    SearchPassenger: () => import('@flightv2/search/components/SearchPassenger.vue'),
    SearchDatePicker: () => import('@flightv2/search/components/SearchDatePicker.vue'),
    SearchDirectFlightSelect: () => import('@flightv2/search/components/SearchDirectFlightSelect.vue'),
    SearchMonthlyCheapestFare: () => import('@flightv2/search/components/SearchMonthlyCheapestFare.vue'),
    PromoCodeInput: () => import('@flightv2/search/components/PromoCodeInput.vue'),
    SearchClassBookingSelect: () => import('@flightv2/search/components/SearchClassBookingSelect.vue'),
    SearchClassFilter: () => import('@flightv2/search/components/SearchClassFilter.vue'),
    SearchCombinationSwitch: () => import('@flightv2/search/components/SearchCombinationSwitch.vue'),
    SearchCarriersSelect: () => import('@flightv2/search/components/SearchCarriersSelect.vue'),
  },
  props: {
    searchData: {
      type: Object,
      default: () => { },
    },
    isResultSearch: {
      type: Boolean,
      default: false,
    },
    isDisabledGroupedItinerary: {
      type: Boolean,
      default: false,
    },
    isDisabledPermittedCariers: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    onMounted(() => {
      if (!props.searchData.flights[0].returnDate
      || moment(props.searchData.flights[0].returnDate) < moment(props.searchData.flights[0].departDate)) {
        const departDate = new Date(props.searchData.flights[0].departDate)
        props.searchData.flights[0].returnDate = moment(new Date(Date.UTC(departDate.getFullYear(), departDate.getMonth(), departDate.getDate() + 3))).format('YYYY-MM-DD')
      }
    })

    function handleDepartDate(val) {
      const departDate = new Date(val)
      const returnDate = new Date(this.searchData.flights[0].returnDate)
      if (departDate > returnDate) {
        this.searchData.flights[0].returnDate = moment(new Date(Date.UTC(departDate.getFullYear(), departDate.getMonth(), departDate.getDate() + 3))).format('YYYY-MM-DD')
      }
    }
    return {
      handleDepartDate,
    }
  },
}
</script>

<style lang="scss" scoped>
#round-trip ::v-deep {
  .divider-after {
    &::after {
      content: "";
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #d3d3d3;
      height: 28px;
      margin: 0 1rem;

      @media (max-width: 768px) {
        content: none;
      }
    }
  }
}

.section-airport {
  position: relative;
  .switch_airport {
    @media (max-width: 767px) {
      position: absolute;
      z-index: 99;
      right: -50px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
